<template>
  <div class="d-flex flex-row justify-content-center">
    <portal to="top-bar">
      <div class="title">
        <h1 v-if="this.$route.meta.action === 'create'">
          {{ $CONFIG('ADMIN').CREATE_USER }}
        </h1>
        <h1 v-else-if="this.$route.meta.action === 'edit'">
          {{ $CONFIG('ADMIN').EDIT_USER }}
        </h1>
      </div>
    </portal>
    <b-overlay :show="loading" class="body-parent">
      <b-card class="shadow-sm va-card text-left">
        <template #header>
          <div>
            <p class="va-card-createuser">
              {{ $CONFIG('ADMIN').USER_FORM }}
            </p>
          </div>
        </template>
        <b-alert v-if="this.isActiveDirectoryMode" show variant="info">
          <div class="text-center">{{ $AUTH('UPDATE_USER_FORM').UPDATE_USER_DISABLED_INFO }}</div></b-alert>
        <GenericFormField
          v-for="(option, key) in userFieldOptions"
          :key="key"
          :fieldId="key"
          :field="option"
          v-model="option.data"
          :checkboxGroupOptions="option.options"
          :readOnly="togglingReadOnly(key)"
          :error="error"
          :errorCode="errorCode"
          @selectAll="selectAllUserPermissions"
          :visible="togglingVisibility(key)"
        >
          <template v-slot:besideInput>
            <b-btn
              v-if="key === 'password' && showPasswordPlaceholder"
              v-b-tooltip:genericFormField.hover
              :title="$LOCAL('ToolTipLabelEdit')"
              variant="primary"
              class="genericEditButton"
              @click="passwordChange"
            >
              <LaEdit height="20px" width="50px" />
            </b-btn>
          </template>
          <template v-slot:belowInput>
            <div
              v-if="key === 'confirm_new_password' && !showPasswordPlaceholder"
            >
              <b-alert show variant="info">
                <ul>
                  <li
                    v-for="(help_text, key) in helpTextForPassword"
                    :key="key"
                  >
                    {{ `${help_text}` }}
                  </li>
                </ul>
              </b-alert>
            </div>
          </template>
        </GenericFormField>
        <div class="actions">
          <b-button
            variant="danger"
            :disabled="loading"
            @click="$router.go(-1)"
          >
            Cancel
          </b-button>
          <b-button
            id="submit-data"
            variant="success"
            @click="submitUserData()"
          >
            <span v-if="!loading">Submit</span>
            <b-spinner v-else small label="Checking.." />
          </b-button>
        </div>
        <error-handler
          v-if="showError === true"
          :error="displayError"
          :dismissible="false"
          variant="danger"
          :showError="showError"
        />
      </b-card>
      <TOTPDeviceList ref="totpDevices" v-if="show2FASection" :totpDevices="this.totpDevices" @deleteTOTPDevice="deleteUserTOTPDevice"/>
    </b-overlay>
  </div>
</template>

<script>
import ErrorHandler from '@/modules/insight/components/ErrorHandler'
import { mapActions, mapState } from 'vuex'
import GenericFormField from '@/modules/config/components/GenericFormField'
import TOTPDeviceList from '@/modules/config/components/TOTPDeviceList'
import ToastMessage from '@/utils/toast_message'
import StringFormatUtils from '@/utils/string_formatter'
import LaEdit from '@/assets/la-edit.svg'
import UserService from '@/modules/auth/services/user.service'
import AdminResetTOTPDeviceServices from '@/modules/config/services/admin_reset_totp_device.service'

export default {
  name: 'CreateEditUser',
  components: {
    ErrorHandler,
    GenericFormField,
    TOTPDeviceList,
    LaEdit
  },
  data: () => ({
    showError: false,
    displayError: {
      code: 0,
      message: ''
    },
    userFields: {},
    loading: false,
    submitPressed: false,
    dataForResponse: {},
    helpTextForPassword: [],
    passwordDisabled: true,
    editPasswordEnabled: true,
    show2FASection: false,
    totpDevices: []
  }),
  async mounted () {
    await this.fetch()
  },
  computed: {
    ...mapState('config/admin', [
      'userFieldOptions',
      'error',
      'errorCode',
      'showToast'
    ]),
    ...mapState('auth', { rootUserData: 'userData' }),
    showPasswordPlaceholder () {
      return (
        this.$route.meta.action === 'edit' && this.editPasswordEnabled === true
      )
    },
    isActiveDirectoryMode () {
      return this.rootUserData.authentication_method === 'AD'
    }
  },
  methods: {
    ...mapActions('config/admin', [
      'createUsers',
      'getUserFieldOptions',
      'getUsers',
      'getUserRecord',
      'updateUserRecord',
      'clearFakePassword',
      'insertFakePassword'
    ]),
    /**
     * Fetch method conditionally fetches information based on the route.
     * If the route is create, userFieldOptions and password help text is fetched to populate the
     * fields
     * Otherwise, userFieldOptions and password help text is fetched to populate the fields and the
     * specified user (based on id) would be fetched
     */
    async fetch () {
      if (this.$route.meta.action === 'create') {
        await this.getUserFieldOptions(this.$route.meta.action) // vuex actions to populate userFieldOptions
        await this.getPasswordHelpText() // method to populate helpTextForPassword
      } else if (this.$route.meta.action === 'edit') {
        await this.getUserFieldOptions(this.$route.meta.action) // vuex actions to populate userFieldOptions
        await this.getPasswordHelpText() // method to populate helpTextForPassword
        await this.getUserRecord(this.$route.params.id) // actions to fetch user record
        await this.get2FAConfigStatus()
        if (this.show2FASection) {
          await this.getUserTOTPDevices()
        }
      }
    },
    /**
     *  get2FAConfigStatus method is to get the the status of 2FA Configuration Feature from backend
     *  that is used to hide/show TOTPDeviceList component.
     */
    async get2FAConfigStatus () {
      const response = await UserService.getBackEnd2FaEnabledForUserStatus()
      this.show2FASection = response['2fa_status']
    },
    /**
     * Get TOTP Device by user from backend and populate this.totpDevices
     */
    async getUserTOTPDevices () {
      const response = await AdminResetTOTPDeviceServices.getDevices(this.$route.params.id)
      this.totpDevices = response.totp_devices
    },
    /**
     * deleteUserTOTPDevice is to delete the selected deviceId and
     * triggered this.getUserTOTPDevices to refresh the TOTP Device List
     */
    async deleteUserTOTPDevice (deviceId) {
      await AdminResetTOTPDeviceServices.deleteDevice(deviceId)
      await this.getUserTOTPDevices()
    },
    /**
     *  getPasswordHelpText grabs the help text within the password field in
     *  userFieldOptions and populates this.helpTextForPassword with an array of strings.
     *  The regex expression looks for characters that are not numbers, lowercase or
     *  uppercase alphabets and replaces them with ''
     */
    async getPasswordHelpText () {
      const helpTextForPasswordString = this.userFieldOptions.password.help_text
      this.helpTextForPassword = StringFormatUtils.formatHelpText(
        helpTextForPasswordString
      )
    },
    /**
     * reformattingofdataforresponse method is used to populate an empty object
     * 'dataForResponse' with the key value pairs corresponding to the backend fields
     * defined.
     */
    reformattingOfDataForResponse () {
      this.userFieldOptions.userStatus.options.forEach(e => {
        if (this.userFieldOptions.userStatus.data.includes(e.value)) {
          this.dataForResponse[e.value] = true
        } else {
          this.dataForResponse[e.value] = false
        }
      })

      for (const field in this.userFieldOptions) {
        this.dataForResponse[field] = this.userFieldOptions[field].data
      }

      delete this.dataForResponse.userStatus
    },
    /**
     * selectalluserspermissions() is a method called when the 'selectAll' event from the
     * child component, 'GenericFormField' is emitted. It populates 'checkbox' data
     * attribute to include all the permissions.
     */

    selectAllUserPermissions (value) {
      const optionValues = []

      for (const option of this.userFieldOptions.userStatus.options) {
        optionValues.push(option.value)
      }

      this.userFieldOptions.userStatus.data = value ? optionValues : []
    },
    /**
     *  submituserdata method calls the vuex action, 'createusers' with the packaged response
     */
    async submitUserData () {
      this.submitPressed = true
      this.reformattingOfDataForResponse()
      if (this.$route.meta.action === 'create') {
        const response = await this.createUsers(this.dataForResponse) // calling actions createUsers with payload
        if (response) {
          ToastMessage.showCreatedSuccess({
            vueInstance: this,
            prefixName: this.$CONFIG('ADMIN').TOAST_TITLE.USER,
            name: this.dataForResponse.username
          })
        } else {
          if (this.showToast) {
            // showToast boolean placed here to prevent error 400 to display toast
            this.$bvToast.toast(this.error.message, {
              title: this.$CONFIG('ADMIN').TOAST_TITLE.ERROR,
              autoHideDelay: 500000,
              variant: 'danger',
              opacity: 1
            })
          }
        }
        if (this.showError === false) {
          this.$router.go(-1) // when creation is successful, redirected to the previous page
        }
      } else if (this.$route.meta.action === 'edit') {
        const data = this.dataForResponse
        const id = this.$route.params.id
        const passwordDisabled = this.passwordDisabled
        this.submitPressed = true
        const response = await this.updateUserRecord({
          data,
          id,
          passwordDisabled
        }) // calling actions updateUserRecord with payload
        if (response) {
          ToastMessage.showEditedSuccess({
            vueInstance: this,
            prefixName: this.$CONFIG('ADMIN').TOAST_TITLE.USER,
            name: this.dataForResponse.username
          })
        } else {
          if (this.showToast) {
            // showToast boolean placed here to prevent error 400 to dislpay toast
            this.$bvToast.toast(this.error.message, {
              title: this.$CONFIG('ADMIN').TOAST_TITLE.ERROR,
              autoHideDelay: 500000,
              variant: 'danger',
              opacity: 1
            })
          }
        }
        if (this.showError === false) {
          this.$router.go(-1)
        }
      }
    },
    /**
     * passwordChange is triggered when the edit button is pressed.
     * editPasswordEnabled is toggled to be false to make the button disappear
     * clearFakePassword action is used to clear the fake password from the state userFieldOptions
     * insertFakePassword action is used to add in the fake password into the state userFieldOptions
     */
    passwordChange () {
      this.passwordDisabled = !this.passwordDisabled
      this.editPasswordEnabled = false
      if (!this.passwordDisabled) {
        this.clearFakePassword()
      } else {
        this.insertFakePassword()
      }
    },
    /**
     * togglingVisibility is used to hide confirm_new_password field by returning the boolean variable of
     * true
     * Otherwise, it would return the negated value of passwordDisabled, by default which is true, hence
     * return false.
     */
    togglingVisibility (key) {
      if (this.$route.meta.action === 'edit') {
        return key === 'confirm_new_password' ? !this.passwordDisabled : true
      } else {
        return true
      }
    },
    /**
     * togglingReadOnly method is used to disable specific fields, in this case, password and the
     * userStatus (checkboxes).
     * If the field is password and passwordDisabled is true as well as showPasswordPlaceholder, the
     * password field would be read-only.
     * However, the user is editing itself, the logged in user, it would return true to toggle the
     * checkboxes as read-only.
     */
    togglingReadOnly (key) {
      // If authentication method is Active Directory (AD), the fields in the form will be disabled for editing.
      // Leaving only the user status for updating.
      if (this.isActiveDirectoryMode) {
        if (
          this.$route.meta.action === 'edit' &&
          key === 'userStatus' &&
          this.userFieldOptions.username.data === this.rootUserData.username
        ) {
          return true
        } else if (this.$route.meta.action === 'edit' &&
          key === 'userStatus' &&
          this.userFieldOptions.username.data !== this.rootUserData.username) {
          return false
        } else {
          return true
        }
      } else {
        if (
          key === 'password' &&
          this.passwordDisabled === true &&
          this.showPasswordPlaceholder === true
        ) {
          return true
        } else if (
          this.$route.meta.action === 'edit' &&
          key === 'userStatus' &&
          this.userFieldOptions.username.data === this.rootUserData.username
        ) {
          return true
        } else {
          return false
        }
      }
    }
    // async cancelConfirmation (to, from, next) {
    //   if (!this.submitPressed) {
    //     const cancelConfirmation = await this.$bvModal.msgBoxConfirm(
    //       this.$CONFIG('ADMIN').MODAL_CONTENT.DISCARDING_CHANGES_BODY,
    //       {
    //         title: `${
    //           this.$CONFIG('ADMIN').MODAL_CONTENT.DISCARDING_CHANGES_TITLE
    //         }`,
    //         cancelVariant: 'danger',
    //         okVariant: 'success',
    //         noCloseOnBackdrop: true
    //       }
    //     )
    //     if (cancelConfirmation) {
    //       next()
    //     } else {
    //       //  preserve the routing history stack if cancelled
    //       this.$router.go(1)
    //     }
    //   } else {
    //     next()
    //   }
    // },
  },

  watch: {
    errorCode (err) {
      if (err === 0) {
        // Validation Error
        this.showError = false
        this.displayError = {
          code: 0,
          message: ''
        }
      } else {
        this.showError = true
        this.displayError = {
          code: err,
          message: this.$INSIGHT('ERROR').SUBMISSION_ERROR
        }
      }
    }
  }

  // beforeRouteLeave (to, from, next) {
  //   // this.cancelConfirmation(to, from, next)
  // }
}
</script>

<style scoped>
.body-parent {
  width: 65%;
}
.userTitle {
  padding: 0 15px 0 15px;
}
.userTitle h1 {
  font-size: 1.5rem;
  padding: 0;
  margin: 0;
}
.va-card-createuser {
  font-size: 1.2rem;
  font-weight: 300;
  margin: 0;
}
.va-card-edituser {
  font-size: 1.2rem;
  font-weight: 300;
  margin: 0;
}
.fieldrows {
  margin-bottom: 1rem;
}
.fieldLabel {
  padding: 7px 5px;
}
.actions {
  display: flex;
  margin-top: 15px;
  justify-content: space-between;
}

.genericEditButton {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
</style>
