<template>
  <wizard-container>
    <div>
      <b-card class="va-card fade-in" :header="header">
        <div class="index-status-container">
          <IndexStatusItem class="index-status-item" :indexItemList="generateEntityTypes" :indexCategoryTitle="entityCaption"
                              :disableReIndexButtonBoolean="disableReIndexButtonBoolean"/>
          <IndexStatusItem class="index-status-item" :indexItemList="generateLinkTypes" :indexCategoryTitle="linkCaption"
                  :disableReIndexButtonBoolean="disableReIndexButtonBoolean"/>
          <IndexStatusItem class="index-status-item" :indexItemList="generateOtherTypes" :indexCategoryTitle="othersCaption"
                  :disableReIndexButtonBoolean="disableReIndexButtonBoolean"/>
        </div>
      </b-card>
    </div>
  </wizard-container>
</template>

<script>
import WizardContainer from '../../components/Container'
import { mapActions, mapState } from 'vuex'
import '@/dependencies/vue_moment_dependencies'
import IndexStatusItem from '../components/IndexStatusItem'

export default {
  name: 'SourceDBIndex',
  components: {
    WizardContainer,
    IndexStatusItem
  },
  data: () => ({
    generateEntityTypes: [],
    generateLinkTypes: [],
    generateOtherTypes: [],
    generateAllTypes: [],
    intervalEnabled: true,
    webSocketUrl: '/ws/insight/indexing_status/'
  }),
  async mounted () {
    await this.fetchIndexDetails()
    this.filterIndexingStatus(this.indexDetails)

    // Open a websocket connection to receive the index status update from the server in real time
    await this.openWebSocketConnection(
      {
        url: this.webSocketUrl,
        encodeMessage: false,
        onmessage: this.onmessage
      })
  },
  async destroyed () {
    // Gracefully close the websocket connection to prevent further automatic reconnection when connection expires
    await this.closeWebSocketConnection({ url: this.webSocketUrl })
  },
  computed: {
    // get the state from the store
    ...mapState('config/insight', ['entityTypes', 'linkTypes', 'generatingErrors', 'indexDetails']),
    header () {
      return `${this.$CONFIG('WIZARD_PAGE_4').TEXT.SOURCE_DATABASE_INDEX}`
    },
    entityCaption () {
      const entityLength = this.generateEntityTypes.length
      return `${this.$CONFIG('WIZARD_PAGE_4').TEXT.ENTITY_TYPES} (${entityLength})`
    },
    linkCaption () {
      const entityLength = this.generateLinkTypes.length
      return `${this.$CONFIG('WIZARD_PAGE_4').TEXT.LINK_TYPES} (${entityLength})`
    },
    othersCaption () {
      const entityLength = this.generateOtherTypes.length
      return `${this.$LOCAL('COMMON_WORD').OTHERS} (${entityLength})`
    },
    disableReIndexButtonBoolean () {
      return !(
        this.generateAllTypes.reduce((current, next) => current && !!(next.status === 'Indexing Completed'), true)
      )
    }
  },
  methods: {
    ...mapActions('config/insight', [
      'fetchIndexDetails'
    ]),
    ...mapActions('auth', [
      'openWebSocketConnection', 'closeWebSocketConnection'
    ]),
    filterIndexingStatus (items) {
      this.generateAllTypes = items
      this.generateEntityTypes = items.filter(item => item.is_entity === true)
      this.generateLinkTypes = items.filter(item => item.is_entity === false)
      this.generateOtherTypes = items.filter(item => item.is_entity === null || item.is_entity === undefined)
    },
    onmessage (event) {
      this.filterIndexingStatus(event.message)
    }
  }
}
</script>

<style lang="scss" scoped>
  .index-status-container {
      display: flex;
      flex-wrap: wrap;
  }
  .index-status-item {
      width: 50%;
      min-width: 30rem;
      padding-bottom: 0.5rem;
  }
</style>
