import { render, staticRenderFns } from "./GroupPermissions.vue?vue&type=template&id=a5e5d022&scoped=true&"
import script from "./GroupPermissions.vue?vue&type=script&lang=js&"
export * from "./GroupPermissions.vue?vue&type=script&lang=js&"
import style0 from "vue-multiselect/dist/vue-multiselect.min.css?vue&type=style&index=0&lang=css&"
import style1 from "./GroupPermissions.vue?vue&type=style&index=1&id=a5e5d022&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a5e5d022",
  null
  
)

export default component.exports