<template>
  <div class="create-group" id="create-group">
    <portal to="top-bar">
      <div class="title d-flex" v-if="this.$route.meta.action === 'create'">
        <h1>{{ $LOCAL('ToolTipLabelNew') }} {{ $LOCAL('Group') }}</h1>
      </div>
      <div class="title d-flex" v-if="this.$route.meta.action === 'edit'">
        <h1>{{ $LOCAL('COMMON_WORD').EDIT }}</h1>
      </div>
    </portal>
    <div class="button-options p-3 w-100">
      <div class="float-left d-flex">
        <label for="form-name-input" class="font-weight-bold mr-2 mt-2"
          >{{ $LOCAL('COMMON_WORD').NAME }}:</label
        >
        <b-form-group
          class="text-left"
          v-model="this.inputNameErrorMessage"
          :state="this.inputNameValid"
          :invalid-feedback="this.inputNameErrorMessage"
        >
          <b-form-input
            :state="this.inputNameValid"
            v-model="groupName"
            id="form-name-input"
            :invalid-feedback="this.inputNameErrorMessage"
          ></b-form-input>
        </b-form-group>
      </div>
    </div>
    <div>
      <div class="container-fluid my-5 main-content">
        <div class="card w-100">
          <div class="card-header text-white">
            <p class="d-flex m-0">
              {{ $LOCAL('COMMON_WORD').ASSIGN }} {{ $LOCAL('User') }}
            </p>
          </div>
          <div class="card-body">
            <div class="row px-5">
              <div class="w-100 p-3 selectUserDesign">
                <strong>{{ $LOCAL('User') }}:</strong>
                <multiselect
                  class="custom-multiselect"
                  track-by="id"
                  label="username"
                  :options="userOptions"
                  :placeholder="
                    $CONFIG('ADMIN').CREATE_GROUPS.SELECT_AVAIALBLE_USERS
                  "
                  :closeOnSelect="false"
                  :taggable="false"
                  :multiple="true"
                  v-model="userSelected"
                  :selectLabel="$LOCAL('COMMON_WORD').SELECT"
                  :deselectLabel="$LOCAL('COMMON_WORD').REMOVE"
                >
                  <template slot-scope="{ option }">{{
                    option.username
                  }}</template>
                </multiselect>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <GroupPermissions
      class="grouppermissions"
      :permission="titleList"
      :permissionSub="permissionSubData"
      :permissionSubInfo="permissionSubDataInfo"
      :permissionSubTitleInfo="permissionSubTitle"
      :permissionSubTitleInfoText="permissionSubTitleText"
      :existingSelectedPermissionItem="existingPermissionItemsSelected"
      :permissionHelperInfoText="permissionHelperInfoText"
      @titleSelected="getPermissionCategory"
      @titleSubSelected="getPermissionCategoryItems"
      @selectedPermissionItems="selectedPermissionItems"
    />
    <div class="container-fluid d-flex justify-content-between">
      <router-link :to="{ name: 'Settings Manage Group' }"
        ><b-button variant="danger">{{
          $LOCAL('COMMON_WORD').CANCEL
        }}</b-button></router-link
      >
      <b-button variant="success" @click="submitData()">{{
        $LOCAL('COMMON_WORD').SUBMIT
      }}</b-button>
    </div>
  </div>
</template>

<script>
import multiselect from 'vue-multiselect'
import { mapState, mapActions } from 'vuex'
import { capitalize, differenceWith, isEqual } from 'lodash'
import ToastMessage from '@/utils/toast_message'
import GroupPermissions from '@/modules/config/views/components/GroupPermissions'
import CONFIG from '@/constants/CONFIG.json'

export default {
  name: 'CreateEditGroup',
  components: {
    multiselect,
    GroupPermissions
  },
  data () {
    return {
      permissionSubDataInfo: [],
      permissionSubData: {},
      permissionSubTitle: '',
      permissionSubTitleText: '',
      permissionHelperInfoText: '',
      inputNameValid: null,
      inputNameErrorMessage: null,
      userSelected: [],
      groupName: '',
      permissionItemsSelected: [],
      existingPermissionItemsSelected: [],
      itemsRemovedFromPermissions: [],
      itemsAddedIntoPermissions: []
    }
  },
  computed: {
    ...mapState('config/admin', {
      userOptions: ['userOptions'],
      error: 'error',
      titleList: 'groupPermissionList'
    })
  },
  /**
   * During mounted, the function/method will run the async function for getUserOptions from the
   * vuex store actions to mutate the current empty array with values from the user list at the backend
   * of class UserViewSet
   */
  async mounted () {
    if (this.$route.meta.action === 'create') {
      await this.getUserOptions() // populate the users choices
      await this.getPermissions()
      if (this.titleList && 'Personalisation' in this.titleList) {
        // Below options are removed because a group will need to be assigned to a form/query first before it can be starred
        delete this.titleList.Personalisation['Starred Form']
        delete this.titleList.Personalisation['Starred Query']
      }
      this.getPermissionCategory('Entity')
    } else if (this.$route.meta.action === 'edit') {
      await this.getUserOptions() // populate the users choices
      // retrieving group information
      const groupInformation = await this.getGroupInformation(
        this.$route.params.id
      )

      this.groupName = groupInformation.name // assigning the group name to the v-model
      this.userSelected = groupInformation.user_set // assigning the users who are in the selected group to the v-model userSelected

      await this.getPermissions(this.$route.params.id) // populate the permission choices
      const permissions = await this.getSelectedUsersPermissions(
        this.$route.params.id
      )
      this.existingPermissionItemsSelected = permissions
      this.permissionItemsSelected = permissions
      this.getPermissionCategory('Entity')
      this.groupId = this.$route.params.id
    }
  },
  methods: {
    ...mapActions('config/admin', [
      'getUserOptions',
      'postCreateGroups',
      'getPermissions',
      'getSelectedUsersPermissions',
      'updateGroup',
      'updateGroupPermission',
      'getGroupInformation'
    ]),
    /**
     * This method listen to the emit signal from GroupPermission and get the permission category
     */
    getPermissionCategory (titleId) {
      this.permissionHelperInfoText =
        CONFIG.ADMIN.GROUP_PERMISSION_SETTINGS_HELPER_TEXT[titleId]
      this.permissionSubTitle = titleId
      this.permissionSubData = this.titleList[titleId]
      this.getPermissionCategoryItems(Object.keys(this.titleList[titleId])[0])
    },
    /**
     * This method listen to the emit signal from GroupPermission and get the permission items
     */
    getPermissionCategoryItems (titleSubName) {
      this.permissionSubTitleText = titleSubName
      for (const subItems in this.permissionSubData) {
        if (subItems === titleSubName) {
          this.permissionSubDataInfo = this.permissionSubData[subItems]
        }
      }
    },
    /**
     *  This method compares the newly selected permissions by the user with the previous permissions
     *  selected
     */
    getRemovedItemsAndAddeditems () {
      // In this case, the target array is permissionItemsSelected while the comparison array is existingPermissionItemsSelected
      this.itemsAddedIntoPermissions = differenceWith(
        this.permissionItemsSelected,
        this.existingPermissionItemsSelected,
        isEqual
      )
      // In this case, the target array is existingPermissionItemsSelected, while the comparison array is
      // permissionItemsSelected
      this.itemsRemovedFromPermissions = differenceWith(
        this.existingPermissionItemsSelected,
        this.permissionItemsSelected,
        isEqual
      )
    },
    /**
     * This method will be evoke when submit button is clicked.
     * The group name, user list and permission list will be sent to the backend
     */
    async submitData () {
      let permissionData = {}
      let success
      let showToastMessage
      if (this.$route.meta.action === 'edit') {
        showToastMessage = ToastMessage.showEditedSuccess
        this.getRemovedItemsAndAddeditems()
        permissionData = {
          permissions: this.permissionItemsSelected,
          permissionItemsAdded: this.itemsAddedIntoPermissions, // additional field permissionItemsAdded
          permissionItemsRemoved: this.itemsRemovedFromPermissions // additional field permissionItemsRemoved
        }
        const id = this.groupId
        // TODO consider refactoring the 2 API call into one
        // First update the group
        success = await this.updateGroup({
          id,
          data: {
            name: this.groupName,
            user_set: this.userSelected
          }
        })
        // If update group successful, then update the group permissions
        if (success) {
          success = await this.updateGroupPermission({
            id,
            data: permissionData
          })
        }
      } else {
        // If meta is create
        showToastMessage = ToastMessage.showCreatedSuccess
        permissionData = {
          name: this.groupName,
          user_set: this.userSelected,
          permissions: this.permissionItemsSelected
        }
        success = await this.postCreateGroups(permissionData)
      }

      if (success) {
        showToastMessage({
          vueInstance: this,
          name: this.groupName
        })
        await this.$router.push({ path: '/settings/manage-group' })
      }
    },
    /**
     * This method listen to the emit signal from GroupPermission and update the permission list to submit
     */
    selectedPermissionItems (permissionItemList) {
      this.permissionItemsSelected = permissionItemList
    }
  },
  watch: {
    /**
     * To watch the error handler and display the error.
     */
    error: {
      handler (error) {
        if (error.name) {
          this.inputNameValid = false
          this.inputNameErrorMessage = capitalize(error.name)
        } else {
          this.inputNameValid = true
          this.inputNameErrorMessage = ''
        }
        if (error.permissions) {
          ToastMessage.showErrorDefault({
            vueInstance: this,
            textMessage: error.permissions
          })
        }
      }
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style scoped lang="scss">
.active {
  background-color: #f08521;
  color: white;
  border: none;
}
.activeTitle {
  background-color: #313947;
  color: white;
  border: none;
}
.card-header {
  background-color: #313947;
}
.selectUserDesign {
  border: 1px dashed #91b0b3;
  text-align: start;
}
.selectAllSwitchButton {
  width: 7%;
}
.selectAllSwitch {
  padding-right: 12px;
}
</style>
