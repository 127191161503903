import { ConfigurationError } from '../errors/configuration_error'
import ApiService from '@/modules/auth/services/api.service'

const AdminResetTOTPDeviceServices = {
  /**
   * This API call is to get list of TOTP Device of user
   * @returns {Array} An array of totp device objects
   */
  getDevices: async function (params) {
    try {
      const response = await ApiService.get(`/insight_admin/admin_reset_totp_device/?user_id=${params}`)
      return response.data
    } catch (error) {
      throw new ConfigurationError(error.response.status, error.response.data)
    }
  },
  /**
   * This API call is to delete user's TOTP Device
   */
  deleteDevice: async function (id) {
    try {
      const response = await ApiService.delete(`/insight_admin/admin_reset_totp_device/${id}/`)
      return response.data
    } catch (error) {
      throw new ConfigurationError(error.response.status, error.response.data)
    }
  }
}

export { AdminResetTOTPDeviceServices }
export default AdminResetTOTPDeviceServices
