<template>
  <div id="totp-device-list" class="my-4">
    <b-card class="shadow-sm va-card text-left">
      <template #header>
        <div>
          <p class="va-card-2fa-auth-title">
            {{ $CONFIG('TOTP_DEVICE_LIST_SECTION').TITLE }}
          </p>
        </div>
      </template>
      <div>
        <span class="small">{{ $CONFIG('TOTP_DEVICE_LIST_SECTION').DESCRIPTION }}</span>
        <b-list-group v-if="this.totpDevices.length >  0">
          <b-list-group-item v-for="(device, index) of this.totpDevices" :key="index"
           class="item-card fade-in-fast"
           ref="totpDeviceList"
           :id="`${index}`">
            <b-row>
              <b-col cols="auto" class="mr-auto" align-self="center">{{device.name}}</b-col>
              <b-col cols="auto">
                <b-button size="sm" variant="danger" v-b-tooltip:totp-device-list.hover
                          :title="$LOCAL('ToolTipLabelDelete')" @click="deviceRemove(device)">
                  <LaTrash height="20px" />
                </b-button>
              </b-col>
            </b-row>
          </b-list-group-item>
        </b-list-group>
        <div v-if="this.totpDevices.length === 0">
          <b-card id="publish-status" class="mt-2 publish-status" no-body>
            <b-card-body>
              <b-row class="text-center">
                <b-col><span class="title">{{ $CONFIG('TOTP_DEVICE_LIST_SECTION').NO_AUTHENTICATOR_REGISTERED }}</span></b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </div>
      </div>
    </b-card>
    <delete-modal
      item=""
      :itemId="deviceNameToDelete + ' Device'"
      :modalShow="showDeleteModal"
      @ok="confirmDelete"
      @hide="cancelDelete"
      :ref="$FORM('REFS').DELETE_MODAL"
    >
    </delete-modal>
  </div>

</template>

<script>
import DeleteModal from '@/modules/insight/components/DeleteModal'
import LaTrash from '@/assets/la-trash.svg'

export default {
  name: 'TOTPDeviceList',
  components: {
    DeleteModal,
    LaTrash
  },
  props: {
    totpDevices: {
      type: Array
    }
  },
  data: () => ({
    deviceIdToDelete: '',
    deviceNameToDelete: '',
    showDeleteModal: false
  }),
  methods: {
    /**
     * deviceRemove will show confirmation modal before to deleting device
     */
    async deviceRemove (device) {
      this.showDeleteModal = !this.showDeleteModal
      this.deviceIdToDelete = device.id
      this.deviceNameToDelete = device.name
    },
    /**
     * confirmDelete will be emit deleteTOTPDevice on parent component to delete the selected device
     */
    async confirmDelete () {
      this.$emit('deleteTOTPDevice', this.deviceIdToDelete)
    },
    /**
     * cancelDelete method is to dismiss the modal
     */
    cancelDelete () {
      this.showDeleteModal = false
    }
  }
}
</script>

<style scoped>
.va-card-2fa-auth-title {
  font-size: 1.2rem;
  font-weight: 300;
  margin: 0;
}
</style>
