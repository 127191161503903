<template>
  <b-card
    class="va-card config-card"
    :header="$CONFIG('DROP_SOURCE_DB_CONNECTION').HEADER"
  >
    <div>
      <p>{{ $CONFIG('DROP_SOURCE_DB_CONNECTION').MESSAGE.CARD_MESSAGE }}</p>
    </div>
    <div class="config-actions">
      <b-button variant="danger" @click="onCancel">
        <span>{{ $CONFIG('DROP_SOURCE_DB_CONNECTION').ACTIONS.CANCEL }}</span>
      </b-button>

      <b-button variant="success" @click="showModal = !showModal">
        <span>{{ $CONFIG('DROP_SOURCE_DB_CONNECTION').ACTIONS.CONTINUE }}</span>
      </b-button>
    </div>
    <ConfirmationModal
      :showModal="showModal"
      :modalInputModal="confirmationTextModel"
      :modalMessage="$CONFIG('DROP_SOURCE_DB_CONNECTION').MESSAGE.MODEL_MESSAGE"
      @submitPressed="onConfirm"
      @cancelPressed="clearConfirmationText"
      :modalOkButtonTitle="$CONFIG('DROP_SOURCE_DB_CONNECTION').ACTIONS.CONFIRM"
      :modalCancelButtonTitle="
        $CONFIG('DROP_SOURCE_DB_CONNECTION').ACTIONS.CANCEL
      "
      @confirmationTextInput="setConfirmationText"
      :modalTitle="$CONFIG('DROP_SOURCE_DB_CONNECTION').HEADER"
    />
  </b-card>
</template>

<script>
import { mapActions } from 'vuex'
import ConfirmationModal from '@/modules/config/components/ConfirmationModal'

export default {
  name: 'DropSourceDBConnection',
  data: () => ({
    showModal: false,
    confirmationTextModel: ''
  }),
  components: {
    ConfirmationModal
  },
  methods: {
    ...mapActions('config', ['restartServer']),
    ...mapActions('config/insight', ['dropSourceDBConnection']),
    async onConfirm () {
      if (
        this.confirmationTextModel ===
        this.$CONFIG('DROP_SOURCE_DB_CONNECTION').CONFIRM_TEXT.YES
      ) {
        await this.dropSourceDBConnection()
        this.$bvToast.toast(
          this.$CONFIG('DROP_SOURCE_DB_CONNECTION').MESSAGE
            .CONNECTION_DROP_SUCCESS_MESSAGE,
          {
            title: this.$CONFIG('DROP_SOURCE_DB_CONNECTION').TOAST_TITLE
              .SUCCESS,
            autoHideDelay: 5000,
            variant: 'success',
            opacity: 1
          }
        )
        await this.restartServer()
        this.$router.push({ name: 'logout' })
      } else {
        this.$bvToast.toast(this.$LOCAL('CONFIRMATION_ERROR_MESSAGE'), {
          title: this.$CONFIG('DROP_SOURCE_DB_CONNECTION').TOAST_TITLE.ERROR,
          autoHideDelay: 5000,
          variant: 'danger',
          opacity: 1
        })
      }
    },
    async onCancel () {
      this.$router.push({ name: 'Settings' })
    },
    clearConfirmationText () {
      this.confirmationTextModel = ''
    },
    setConfirmationText (value) {
      this.confirmationTextModel = value
    }
  }
}
</script>

<style scoped lang="scss">
.modal-class {
  display: flex;
  flex-direction: row;

  p {
    flex-grow: 0;
  }
}
</style>
