<template>
  <b-modal
    :visible="showModal"
    :title="modalTitle"
    @ok="$emit('submitPressed')"
    @hide="$emit('cancelPressed')"
    okVariant="success"
    :okTitle="modalOkButtonTitle"
    :cancelTitle="modalCancelButtonTitle"
    cancelVariant="danger"
  >
    <div class="modal-class">
      <p class="my-1"><WarningIcon /></p>
      <p class="my-4">
        {{ this.modalMessage }}
      </p>
    </div>
    <b-form-input
      class="w-100"
      @update="$emit('confirmationTextInput',$event)"
      :placeholder="$LOCAL('CONFIRMATION_PLACEHOLDER')"
    />
  </b-modal>
</template>

<script>
import WarningIcon from '@/assets/la-warning-triangle.svg'

export default {
  name: 'ConfirmationModal',
  components: {
    WarningIcon
  },
  computed: {},
  props: {
    showModal: {
      type: Boolean,
      default: false
    },
    modalTitle: {
      type: String,
      default: ''
    },
    modalOkButtonTitle: {
      type: String,
      default: ''
    },
    modalCancelButtonTitle: {
      type: String,
      default: ''
    },
    modalMessage: {
      type: String,
      default: ''
    },
    modalInputModel: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped lang="scss">
.modal-class {
  display: flex;
  flex-direction: row;

  p {
    flex-grow: 0;
  }
}
</style>
