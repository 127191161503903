<template>
  <b-modal
      ref="sync-modal"
      :title="$AUTH('USER_MANAGEMENT').ACTIVE_DIRECTORY.SYNC_USERS_MODAL.TITLE"
      no-close-on-backdrop
      no-close-on-esc
      hide-header-close
      :size="modalSize"
      >
    <b-container v-if="showSummary" fluid>
      <b-tabs content-class="mt-3">
        <b-tab v-for="result in results" :key="result.title" :title="result.title">
          <b-alert show variant="info">
            {{ result.help_text }}
          </b-alert>
          <b-table
            :id="result.title"
            :items="result.data"
            :per-page="perPage"
            :current-page="result.currentPage"
            :fields="orderColumnsBy"
            :sort-by.sync="sortBy"
            small
            striped
          >
            <template
                v-for="booleanHeader in booleanHeaders"
                v-slot:[`cell(${booleanHeader})`]="data">
              <BlackTickIcon :key="booleanHeader" v-if="data.value === true" />
            </template>
          </b-table>
          <b-pagination
            v-model="result.currentPage"
            :total-rows="result.data.length"
            :per-page="perPage"
            :aria-controls="result.title"
          ></b-pagination>
        </b-tab>
      </b-tabs>
    </b-container>
    <b-container v-else fluid>
      <b-row class="mb-2" align-v="center">
        <b-col sm="3" class="text-center">
          <WarningIcon />
        </b-col>
        <b-col sm="9">
          <p>{{ $AUTH('USER_MANAGEMENT').ACTIVE_DIRECTORY.SYNC_USERS_MODAL.INSTRUCTION }}</p>
        </b-col>
      </b-row>
      <b-row class="text-center mb-2" align-v="center">
        <b-col>
          <b-form-input type="text" v-model="syncUsername" :disabled="syncLoading" :placeholder="$AUTH('USER_MANAGEMENT').ACTIVE_DIRECTORY.SYNC_USERS_MODAL.PLACEHOLDER_USERNAME"></b-form-input>
        </b-col>
      </b-row>
      <b-row class="text-center" align-v="center">
        <b-col>
          <b-form-input type="password" v-model="syncPassword" :disabled="syncLoading" :placeholder="$AUTH('USER_MANAGEMENT').ACTIVE_DIRECTORY.SYNC_USERS_MODAL.PLACEHOLDER_PASSWORD"></b-form-input>
        </b-col>
      </b-row>
      <b-row class="ml-2 mr-2 mt-2 text-danger text-center" align-v="center" v-if="error != ''">
        <b-col sm="12">
          {{ error }}
        </b-col>
      </b-row>
    </b-container>
    <template #modal-footer>
      <b-button variant="danger" @click="emitHideSyncModal()" :disabled="syncLoading">
        {{ $LOCAL('COMMON_WORD').CANCEL }}
      </b-button>
      <b-button v-if="syncLoading && !showSummary" variant="success">
        <b-spinner small></b-spinner>
      </b-button>
      <b-button v-if="!syncLoading && !showSummary" variant="success" @click.prevent="sync()">
        {{ $LOCAL('COMMON_WORD').SYNC }}
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import Vue from 'vue'
import WarningIcon from '@/assets/la-warning-triangle.svg'
import BlackTickIcon from '@/assets/la-check.svg'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'SyncActiveDirectoryUsersModal',
  props: {
    isSyncModal: Boolean
  },
  components: {
    WarningIcon,
    BlackTickIcon
  },
  data: () => ({
    syncUsername: '',
    syncPassword: '',
    results: [],
    helpTexts: {
      created: Vue.prototype.$AUTH('USER_MANAGEMENT').SYNC_CREATED_USERS_HELP_TEXT,
      updated: Vue.prototype.$AUTH('USER_MANAGEMENT').SYNC_UPDATED_USERS_HELP_TEXT,
      disabled: Vue.prototype.$AUTH('USER_MANAGEMENT').SYNC_DISABLED_USERS_HELP_TEXT
    },
    perPage: 10,
    booleanHeaders: ['active', 'staff', 'superuser'], // Names have to match those headers in results
    orderColumnsBy: ['username', 'first_name', 'last_name', 'email', 'active', 'staff', 'superuser'],
    sortBy: 'username'
  }),
  computed: {
    ...mapState('config/insight', {
      error: 'error',
      syncLoading: 'loading'
    }),
    showSummary () {
      if (this.results === undefined || this.results === null || this.results.length === 0) {
        return false
      } else {
        return true
      }
    },
    modalSize () {
      if (this.showSummary) {
        return 'xl'
      } else {
        return ''
      }
    }
  },
  watch: {
    isSyncModal (newValue) {
      if (newValue === true) {
        this.showSyncModal()
      } else if (newValue === false) {
        this.hideSyncModal()
      }
    }
  },
  methods: {
    ...mapActions('config/insight', [
      'syncActiveDirectory'
    ]),
    emitHideSyncModal () {
      this.$emit('hideSyncModal')
    },
    showSyncModal () {
      this.resetSyncModal()
      this.$refs['sync-modal'].show()
    },
    hideSyncModal () {
      this.$refs['sync-modal'].hide()
      this.resetSyncModal()
    },
    resetSyncModal () {
      this.syncUsername = ''
      this.syncPassword = ''
      this.results = null
    },
    async sync () {
      this.results = await this.syncActiveDirectory({
        username: this.syncUsername,
        password: this.syncPassword
      })

      this.$emit('refreshUserList')
    }
  }
}
</script>

<style scoped>
</style>
