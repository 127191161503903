<template>
    <b-card class="va-card" :header="$CONFIG('SOURCE_DB_INDEX_SCHEDULER').HEADER">
      <generic-form-field v-for="(value, key) in dbIndexSchedulerFields" :key="key" :ref="key"
       :fieldId="key"
       :field="value"
       :errorCode="errorCode"
       :error="error"
       :visible="value.visible"
       :readOnly="value.readOnly"
       v-model="value.value"
      />

      <div class="config-actions">
        <b-button variant="danger" :disabled="loading" @click="resetDbIndexSchedulerFields">
          <span>{{ $LOCAL('COMMON_WORD').RESET }}</span>
        </b-button>

        <b-button variant="success" :disabled="loading" @click="save">
          <span v-if="!loading">
            {{ $LOCAL('COMMON_WORD').SAVE }}
          </span>
          <b-spinner small v-if="loading === true" :label="`${$LOCAL('COMMON_WORD').CHECKING}...`"></b-spinner>
        </b-button>
      </div>

       <error-handler
         v-if="error"
          :error="errorObj"
          :dismissible="false"
          variant="danger"
          :showError="true"
        />
    </b-card>
</template>

<script>
import GenericFormField from '../../components/GenericFormField'
import ErrorHandler from '@/modules/insight/components/ErrorHandler'
import { mapActions, mapState } from 'vuex'
import { forEach } from 'lodash'

export default {
  name: 'SourceDBIndexScheduler',
  components: {
    GenericFormField,
    ErrorHandler
  },
  data: () => ({
    dbIndexSchedulerFields: {}
  }),
  computed: {
    ...mapState('config/insight', ['error', 'errorCode', 'loading']),
    /**
     * This computed returns the error obj to display whenever the errorCode state changes
     * @return {{code: number, message: (string|*)}} The error obj to display
     */
    errorObj () {
      return {
        code: this.errorCode,
        message: this.errorCode === 400 ? this.$INSIGHT('ERROR').SUBMISSION_ERROR : this.error
      }
    },
    /**
     * This computed is used as a field watcher for scheduler_enabled field. This is to reduce the watch overhead
     * to only watch the scheduler_enabled field change instead of watching the entire dbIndexSchedulerFields object
     * @return {*}
     */
    schedulerEnabledField () {
      return this.dbIndexSchedulerFields?.scheduler_enabled
    },
    /**
     * This computed is used as a field watcher for schedule_type field. This is to reduce the watch overhead
     * to only watch the schedule_type field change instead of watching the entire dbIndexSchedulerFields object
     * @return {*}
     */
    scheduleTypeField () {
      return this.dbIndexSchedulerFields?.schedule_type
    }
  },
  watch: {
    /**
     * This watcher watch the schedulerEnabledField computed changes and handles the change in
     * schedulerEnabledValueChanged method
     */
    schedulerEnabledField: {
      handler: 'schedulerEnabledValueChanged',
      deep: true
    },
    /**
     * This watcher watch the scheduleTypeField computed changes and handles the change in
     * scheduleTypeValueChanged method
     */
    scheduleTypeField: {
      handler: 'scheduleTypeValueChanged',
      deep: true
    }
  },
  async mounted () {
    await this.resetDbIndexSchedulerFields()
  },
  methods: {
    ...mapActions('config/insight', [
      'fetchDatabaseIndexSchedulerFields',
      'saveDatabaseIndexScheduler'
    ]),
    /**
     * This function calls the save action to update the index scheduler
     * @return {Promise<void>}
     */
    async save () {
      const success = await this.saveDatabaseIndexScheduler(this.dbIndexSchedulerFields)

      if (success) {
        await this.$router.push({ name: 'Settings' })
        this.$bvToast.toast(this.$CONFIG('SOURCE_DB_INDEX_SCHEDULER').SUCCESS_MESSAGE, {
          title: this.$LOCAL('COMMON_WORD').SUCCESS,
          autoHideDelay: 5000,
          variant: 'success',
          opacity: 1
        })
      }
    },
    /**
     * This function calls the fetch action to reset the value to original
     * @return {Promise<void>}
     */
    async resetDbIndexSchedulerFields () {
      this.dbIndexSchedulerFields = await this.fetchDatabaseIndexSchedulerFields()
    },
    /**
     * This function is a watch handler which will be triggered when schedulerEnabledField computed changes.
     * It will set all fields readOnly to the opposite of scheduler_enabled except the scheduler_enabled field itself.
     * This will set all fields to readOnly True when scheduler_enabled is false and vice versa.
     * @param schedulerEnabledField
     */
    schedulerEnabledValueChanged (schedulerEnabledField) {
      if (schedulerEnabledField) {
        forEach(this.dbIndexSchedulerFields, (value, key) => {
          if (key === 'scheduler_enabled') {
            value.switchLabel = schedulerEnabledField.value ? 'On' : 'Off'
          } else {
            value.readOnly = !schedulerEnabledField.value
          }
        })
      }
    },
    /**
     * This function is a watch handler which will be triggered when scheduleTypeField computed changes.
     * It will show minute and timezone field if schedule_type is 'F' and set the hourly choice to 1 - 23.
     * Otherwise, it will hide minute and timezone field if schedule_type and set the hourly choice to 3/5/9/12/15/18/21
     * assuming the else refers to schedule_type 'H'
     * @param schedulerEnabledField
     */
    scheduleTypeValueChanged (scheduleTypeField) {
      if (scheduleTypeField) {
        if (scheduleTypeField.value === 'F') {
          this.dbIndexSchedulerFields.minute.visible = true
          this.dbIndexSchedulerFields.timezone.visible = true
          this.dbIndexSchedulerFields.hour.choices = Array(24).fill(1).map((element, index) => {
            return {
              value: index.toString(),
              display_name: index.toString()
            }
          })
        } else {
          this.dbIndexSchedulerFields.minute.visible = false
          this.dbIndexSchedulerFields.timezone.visible = false
          this.dbIndexSchedulerFields.hour.choices = Array(7).fill(1).map((element, index) => {
            return {
              value: ((index + 1) * 3).toString(),
              display_name: ((index + 1) * 3).toString()
            }
          })
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .switch {
    display: flex;
    align-items: flex-start;
    margin-bottom: 1rem;
  }

  .va-card {
    width: 40vw;
    margin: auto;
  }
</style>
