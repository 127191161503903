<template>
  <div class="container-fluid my-5 main-content">
    <div class="card">
      <div class="card-header text-white">
        <p class="d-flex m-0">
          {{ $LOCAL('COMMON_WORD').PERMISSION }}
          {{ $LOCAL('COMMON_WORD').SETTING }}
        </p>
      </div>
      <div class="card-body">
        <div class="row px-5">
          <div class="col-3 float-left">
            <div
              v-for="(permissionSet, name) in permission"
              :key="name"
              class="accordion"
              role="tablist"
            >
              <b-card no-body class="mb-1 card-design">
                <b-card-header
                  header-tag="header"
                  class="p-0 bg-white"
                  role="tab"
                >
                  <button
                    class="btn w-100"
                    variant="dark"
                    :class="{
                      activeTitle: permissionSubTitleInfo === name,
                      'bg-white': permissionSubTitleInfo !== name
                    }"
                    v-b-toggle="'accordion-' + name"
                    @click="showItemsSelected(name)"
                  >
                    {{ name }}
                  </button>
                </b-card-header>
              </b-card>
            </div>
          </div>
          <div class="col-9">
            <b-alert show v-show="permissionHelperInfoText" variant="primary">{{ permissionHelperInfoText }}</b-alert>
            <div class="w-100">
              <b-dropdown
                :text="permissionSubTitleInfoText"
                block
                variant="primary"
                class="w-100 rounded text-center"
                menu-class="w-100"
              >
                <b-dropdown-item-button
                  v-for="(eachItems, index) in permissionSub"
                  @click="displaySelectedSubItems(index)"
                  :key="index"
                  >{{ index }}</b-dropdown-item-button
                >
              </b-dropdown>
            </div>
            <div class="main-content-right py-3 mx-auto">
              <div
                class="d-flex flex-column justify-content-center setting-items"
              >
                <div class="d-flex flex-row justify-content-center my-1">
                  <div v-if="haveNoSelectedPermissionItems" class="col-12">
                    {{ $LOCAL('NO_PERMISSION_ITEM') }}
                  </div>
                  <div
                    v-if="haveSelectedPermissionItems"
                    class="col-11 my-auto"
                  >
                    {{ $LOCAL('COMMON_WORD').SELECTALL }}
                  </div>
                  <div v-if="haveSelectedPermissionItems">
                    <b-form-checkbox
                      v-model="selectAll"
                      @change="selectAllPermissions"
                      class="switch-button"
                      size="lg"
                      switch
                    ></b-form-checkbox>
                  </div>
                </div>
                <div
                  v-for="(eachSubItems, index) in permissionSubInfo"
                  :key="index"
                >
                  <div class="d-flex flex-row justify-content-center my-1">
                    <div class="col-11 my-auto">
                      {{ eachSubItems.name }}
                    </div>
                    <div class="switch-button">
                      <b-form-checkbox
                        :value="eachSubItems.permission_item"
                        v-model="selectedPermissionItems"
                        @change="selectPermission()"
                        size="lg"
                        switch
                      ></b-form-checkbox>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { find, differenceWith, map, isEqual, isEmpty } from 'lodash'
export default {
  name: 'GroupPermissions',
  data () {
    return {
      currentTitle: 'Entity',
      dropdownText: '',
      selectedPermissionItems: [],
      selectAll: false
    }
  },
  computed: {
    haveSelectedPermissionItems () {
      return this.permissionSubInfo.length > 0
    },
    haveNoSelectedPermissionItems () {
      return this.permissionSubInfo.length === 0
    }
  },
  props: {
    permission: {
      type: Object,
      required: true
    },
    permissionSub: {
      type: Object,
      required: true
    },
    permissionSubInfo: {
      type: Array,
      required: true
    },
    permissionSubTitleInfo: {
      type: String,
      required: true
    },
    permissionSubTitleInfoText: {
      type: String,
      required: true
    },
    existingSelectedPermissionItem: {
      type: Array,
      required: false
    },
    permissionHelperInfoText: {
      type: String
    }
  },
  methods: {
    /**
     * This method will emit a signal to update prop permissionSub
     */
    showItemsSelected (name) {
      this.currentTitle = name
      this.$emit('titleSelected', name)
    },
    /**
     * This method will emit a signal to update prop permissionSubInfo
     */
    displaySelectedSubItems (name) {
      this.dropdownText = name
      this.$emit('titleSubSelected', name)
    },
    /**
     * This method bind to the toggle @change and emit a signal to update parent on the
     * permission selected
     */
    selectPermission () {
      this.$emit('selectedPermissionItems', this.selectedPermissionItems)
    },
    /**
     * This method is to handle the select all for both Add and Remove permission items
     */
    selectAllPermissions (checked) {
      if (checked) {
        for (const item of this.permissionSubInfo) {
          // Making use of find() to see if the permission item already exists in selectedPermissionItems
          if (
            typeof find(this.selectedPermissionItems, {
              id: item.permission_item.id
            }) === 'undefined'
          ) {
            this.selectedPermissionItems.push(item.permission_item)
          }
        }
      } else {
        // To iterate through the item list and remove the selected permissions
        for (const item of this.permissionSubInfo) {
          for (const selectedItemIndex in this.selectedPermissionItems) {
            if (
              item.permission_item.id ===
              this.selectedPermissionItems[selectedItemIndex].id
            ) {
              this.selectedPermissionItems.splice(selectedItemIndex, 1)
            }
          }
        }
      }
      this.selectPermission()
    },
    checkIfSelectedAll () {
      /**
       * To check If the group of permission is already all-checked or not.
       * No fancy things here, just check if the permission item list of the sub info
       * exist in selected permission item list
       */
      const permissionItemList = map(this.permissionSubInfo, 'permission_item')
      const differencesFromPermissionItemList = differenceWith(permissionItemList, this.selectedPermissionItems, isEqual)
      this.selectAll = isEmpty(differencesFromPermissionItemList)
    }
  },
  watch: {
    selectedPermissionItems: {
      immediate: true,
      deep: true,
      handler: function () {
        this.checkIfSelectedAll()
      }
    },
    /**
     * This method to watch the prop permissionSubInfo and update other variable accordingly
     */
    permissionSubInfo: {
      immediate: true,
      deep: true,
      handler: function () {
        this.checkIfSelectedAll()
      }
    },
    /**
     * existingSelectedPermissionItems will only be populated once when CreateEditGroup component runs
     * its mount. This watcher reacts by populating the v-model (this.selectedPermissionItems) with the
     * updated values.
     */
    existingSelectedPermissionItem: {
      handler (newval) {
        this.selectedPermissionItems = [...newval]
      }
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style scoped lang="scss">
.active {
  background-color: #f08521;
  color: white;
  border: none;
}
.activeTitle {
  background-color: #313947;
  color: white;
  border: none;
}
.card-header {
  background-color: #313947;
}
.selectUserDesign {
  border: 1px dashed #91b0b3;
  text-align: start;
}
.selectAllSwitchButton {
  width: 7%;
}
.selectAllSwitch {
  padding-right: 12px;
}

.setting-items {
  margin-left: 36px;
}

.switch-button {
  margin-bottom: 5px;
}
</style>
