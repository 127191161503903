<template>
  <div class="settings index">
    <portal to="top-bar">
      <div class="title">
        <h1>{{ $CONFIG('SETTINGS').TITLE }}</h1>
      </div>
    </portal>

    <b-row>
      <b-col>
        <div class="" v-for="(section, index) of cards" :key="index">
          <h2 class="ml-1 d-flex justify-content-start align-items-center">
              <component class="mr-1 header-icon" :is="section.icon"/>
            {{ section.title }}
          </h2>
          <div class="item-cards mb-3">
            <div class="item-card align-items-center setting-item-card" v-for="card of section.subCards" :key="card.name">
              <div class="inner">
                <router-link
                  class="icon-container clickable-card"
                  :to="card.router"
                >
                  <component class="icon" :is="card.icon" />
                  <div>{{ card.name }}</div>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Vue from 'vue'
import VaModule from '@/assets/module.svg'
import LaKey from '@/assets/la-key.svg'
import LaDatabase from '@/assets/la-database.svg'
import LaSitemap from '@/assets/la-sitemap.svg'
import DeleteDatabase from '@/assets/delete-database.svg'
import ArchiveSolid from '@/assets/archive-solid.svg'
import UserIcon from '@/assets/la-user.svg'
import GroupIcon from '@/assets/la-users.svg'
import SourceDBIndexIcon from '@/assets/search-index.svg'
import SourceDBIndexSchedulerIcon from '@/assets/history-solid.svg'

export default {
  name: 'SettingsIndex',
  components: {
    VaModule,
    LaKey,
    LaDatabase,
    LaSitemap,
    DeleteDatabase,
    ArchiveSolid,
    UserIcon,
    GroupIcon,
    SourceDBIndexIcon,
    SourceDBIndexSchedulerIcon
  },
  data: () => ({
    cards: [
      {
        title: 'Module Management',
        icon: 'VaModule',
        subCards: [
          {
            icon: 'VaModule',
            name: Vue.prototype.$CONFIG('SETTINGS').MODULE_MANAGER_CARD.NAME,
            router: {
              name: Vue.prototype.$CONFIG('SETTINGS').MODULE_MANAGER_CARD.ROUTER
            }
          }
        ]
      },
      {
        title: 'User Group Management',
        icon: 'GroupIcon',
        subCards: [
          {
            icon: 'LaKey',
            name: Vue.prototype.$CONFIG('SETTINGS').AUTHENTICATION_MANAGER_CARD
              .NAME,
            router: {
              name: Vue.prototype.$CONFIG('SETTINGS').AUTHENTICATION_MANAGER_CARD
                .ROUTER
            }
          },
          {
            icon: 'UserIcon',
            name: Vue.prototype.$CONFIG('SETTINGS').MANAGER_USER_CARD.NAME,
            router: {
              name: Vue.prototype.$CONFIG('SETTINGS').MANAGER_USER_CARD.ROUTER
            }
          },
          {
            icon: 'GroupIcon',
            name: Vue.prototype.$CONFIG('SETTINGS').MANAGER_GROUP_CARD.NAME,
            router: {
              name: Vue.prototype.$CONFIG('SETTINGS').MANAGER_GROUP_CARD.ROUTER
            }
          }
        ]
      },
      {
        title: 'Database Management',
        icon: 'LaDatabase',
        subCards: [
          {
            icon: 'LaDatabase',
            name: Vue.prototype.$CONFIG('SETTINGS').DATABASE_CONNECTION_CARD.NAME,
            router: {
              name: Vue.prototype.$CONFIG('SETTINGS').DATABASE_CONNECTION_CARD
                .ROUTER
            }
          },
          {
            icon: 'LaSitemap',
            name: Vue.prototype.$CONFIG('SETTINGS').DATABASE_MAPPINGS_CARD.NAME,
            router: {
              name: Vue.prototype.$CONFIG('SETTINGS').DATABASE_MAPPINGS_CARD.ROUTER
            }
          },
          {
            icon: 'SourceDBIndexIcon',
            name: Vue.prototype.$CONFIG('SETTINGS').SOURCE_DATABASE_INDEX_CARD.NAME,
            router: {
              name: Vue.prototype.$CONFIG('SETTINGS').SOURCE_DATABASE_INDEX_CARD.ROUTER
            }
          },
          {
            icon: 'SourceDBIndexSchedulerIcon',
            name: Vue.prototype.$CONFIG('SETTINGS').SOURCE_DATABASE_INDEX_SCHEDULER_CARD.NAME,
            router: {
              name: Vue.prototype.$CONFIG('SETTINGS').SOURCE_DATABASE_INDEX_SCHEDULER_CARD.ROUTER
            }
          },
          {
            icon: 'DeleteDatabase',
            name: Vue.prototype.$CONFIG('SETTINGS').DROP_DATABASE_CONNECTION_CARD
              .NAME,
            router: {
              name: Vue.prototype.$CONFIG('SETTINGS').DROP_DATABASE_CONNECTION_CARD
                .ROUTER
            }
          },
          {
            icon: 'ArchiveSolid',
            name: Vue.prototype.$CONFIG('SETTINGS').AUDIT_DATABASE_CONNECTION_CARD
              .NAME,
            router: {
              name: Vue.prototype.$CONFIG('SETTINGS').AUDIT_DATABASE_CONNECTION_CARD
                .ROUTER
            }
          }
        ]
      }
    ]
  })
}
</script>
<style lang="scss" scoped>
.setting-item-card {
  height: 120px;
}

h2 {
  font-size: 1.3rem;
}
</style>
