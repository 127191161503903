<template>
  <div id="admin-index">
    <div class="admin-interface">
      <portal to="top-bar">
        <div class="title">
          <h1>{{ getTitle }}</h1>
        </div>
        <b-button-group v-if="this.disableCreateButtonForUserManagementPage" size="sm">
          <div>
            <span id="disabled-create-button">
              <b-btn variant="success" disabled>
                {{ $LOCAL('ToolTipLabelNew') }}
              </b-btn>
            </span>
            <b-tooltip target="disabled-create-button">
              Create new users through your organisation's Active Directory
            </b-tooltip>
          </div>
          <div v-if="false">
            <span id="sync-button-wrapper">
              <b-btn variant="success" @click="showSyncModal()">
                {{ $LOCAL('ToolTipLabelSync') }}
              </b-btn>
            </span>
            <b-tooltip target="sync-button-wrapper">{{ $AUTH('USER_MANAGEMENT').ACTIVE_DIRECTORY.SYNC_USERS_TOOLTIP }}</b-tooltip>
            <sync-active-directory-users-modal
                :is-sync-modal="isSyncModal"
                @hideSyncModal="hideSyncModal()"
                @refreshUserList="fetchQueryList(false)">
            </sync-active-directory-users-modal>
          </div>
        </b-button-group>
        <b-button-group v-else size="sm">
          <span>
            <b-btn variant="success" @click="goToCreate()">
              {{ $LOCAL('ToolTipLabelNew') }}
            </b-btn>
          </span>
        </b-button-group>
      </portal>
      <div class="results-area">
        <search-sort-controls
          class="search-controls"
          :hasClearSearchCross="true"
          :hasAdvancedSearch="false"
          :currentSentSearch="searchTerm"
          :isForEntityOrLink="false"
          :loading="loading"
          :showSortAndRefresh="true"
          :hasModelSelection="false"
          :searchCount="dataSearchCount"
          :sortFields="dataItemsField"
          :searchNoun="dataSearchNoun"
          sortCustomIdField="record_id"
          @search="doSearch"
          @sort="sort"
          @refresh="fetchQueryList(false)"
        />
        <index-cards
          :items="dataListOfItems"
          :searchQuery="searchTerm"
          :sortField="sortBy"
          :fields="dataItemsField"
          :helperTextData="helperTextData"
        >
          <template v-slot:name="{ item }">
            <router-link
              :to="{
                name: dataEditItems,
                params: {
                  id: item.id
                }
              }"
            >
              {{ dataLoadCardTitle(item) }}
            </router-link>
          </template>
          <template v-slot:view="{ item }">
            <b-btn
              v-if="isUser === 'overview'"
              v-b-tooltip:admin-index.hover
              :title="$LOCAL('ToolTipLabelView')"
              variant="success"
              class="editbutton"
              @click="
                $router.push({
                  name: isUser ? 'Settings Edit User' : 'Settings Edit Group',
                  params: { id: item.id }
                })
              "
            >
              <LaEye height="20px" />
            </b-btn>
          </template>
          <template v-slot:edit="{ item }">
            <b-btn
              v-b-tooltip:admin-index.hover
              :title="$LOCAL('ToolTipLabelEdit')"
              variant="primary"
              :class="checkLoggedInUserAgainstUser(item)"
              @click="editOption(item)"
            >
              <LaEdit height="20px" />
            </b-btn>
          </template>
          <template v-slot:delete="{ item }">
            <b-btn
              v-if="disableDeleteButton(item)"
              v-b-tooltip:admn-index.hover
              :title="$LOCAL('ToolTipLabelDelete')"
              @click="deleteThisRecord(item)"
              variant="danger"
            >
              <LaTrash height="20px" />
            </b-btn>
          </template>
        </index-cards>
      </div>
      <ConfirmationModal
        :showModal="showDeleteModal"
        :modalTitle="deleteModelTitle"
        :modalOkButtonTitle="$CONFIG('ADMIN').SUBMIT"
        :modalCancelButtonTitle="$CONFIG('ADMIN').CANCEL"
        :modalMessage="deleteModelMessage"
        :modalInputModel="confirmationTextModel"
        @submitPressed="confirmDelete"
        @cancelPressed="cancelDelete"
        @confirmationTextInput="setConfirmationText"
      />
    </div>
    <!--Load more button is only toggled when the page has 10 users-->
    <!--This is done via the availability of users.information.next, which is set to null when the page number is not 10 -->
    <b-button
      v-if="dataLoadMore"
      class="load-more-button mt-2"
      variant="outline"
      @click="fetchQueryList(true)"
    >
      <b-badge pill variant="secondary" v-if="loading === false">{{
        $LOCAL('LoadMore')
      }}</b-badge>
      <b-spinner small v-if="loading === true" label="Loading" />
    </b-button>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapState } from 'vuex'
import SearchSortControls from '@/modules/insight/components/search_sort/SearchSortControls'
import IndexCards from '@/modules/insight/components/IndexCards'
import LaEye from '@/assets/la-eye.svg'
import LaEdit from '@/assets/la-edit.svg'
import LaTrash from '@/assets/la-trash.svg'
import ConfirmationModal from '@/modules/config/components/ConfirmationModal'
import SyncActiveDirectoryUsersModal from './SyncActiveDirectoryUsersModal'

export default {
  name: 'Index',
  components: {
    SearchSortControls,
    IndexCards,
    LaTrash,
    LaEdit,
    LaEye,
    ConfirmationModal,
    SyncActiveDirectoryUsersModal
  },
  props: {
    permission: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    loading: true,
    page: 1,
    users: '',
    fields: {},
    groupFields: {},
    showDeleteModal: false,
    sortBy: ['-is_active', 'id'],
    searchTerm: '',
    selectedCategories: [],
    selectedAccessGroups: [],
    userIdToDelete: '',
    userOrGroupNameToDelete: '',
    confirmationTextModel: '',
    helperTextField: Vue.prototype.$CONFIG('ADMIN').NOT_SEARCHABLE_HELPER_TEXT.FIELD_LIST,
    helperTextTooltip: Vue.prototype.$CONFIG('ADMIN').NOT_SEARCHABLE_HELPER_TEXT.TITLE,
    helperTextData: [],
    isSyncModal: false
  }),
  computed: {
    ...mapState('config/admin', {
      groupItems: 'groupQueries',
      usersInformation: 'usersInformation',
      showToast: 'showToast',
      error: 'error'
    }),
    ...mapState('auth', { rootUserData: 'userData' }),
    deleteModelTitle () {
      return `${this.$LOCAL('COMMON_WORD').DELETE} ${this.userOrGroupNameToDelete}`
    },
    deleteModelMessage () {
      if (this.isUser) {
        return `${this.$CONFIG('ADMIN').MODAL_CONTENT.DELETE_USER_WARNING_MESSAGE} ${
          this.userOrGroupNameToDelete
        }?`
      } else {
        return `${this.$CONFIG('ADMIN').MODAL_CONTENT.DELETE_GROUP_WARNING_MESSAGE} ${
          this.userOrGroupNameToDelete
        }?`
      }
    },
    listOfUsers () {
      const userInformationList = this.usersInformation.results
      //  removes the fields that need not be displayed
      userInformationList?.forEach(e => {
        delete e.is_staff
        delete e.is_superuser
        delete e.is_active
      })
      return userInformationList
    },
    /**
     * groupItems is set from the mapState when method fetchQueryList is called during mount
     */
    listOfGroups () {
      return this.groupItems.results
    },
    /**
     * Used to differentiate between Users or Groups, true if users and false if otherwise
     */
    isUser () {
      //  to toggle between groups and users
      return this.$route.meta.action === 'users'
    },
    /**
     * When its User, returns Manage User else Manage Group
     */
    getTitle () {
      return this.isUser
        ? this.$CONFIG('ADMIN').MANAGE_USER
        : this.$CONFIG('ADMIN').MANAGE_GROUP
    },
    /**
     * Returns the amount of data from Group or User
     */
    dataSearchCount () {
      return this.isUser
        ? this.usersInformation.count || 0
        : this.groupItems.count || 0
    },
    /**
     * If its User, returns User(s) else Group(s)
     */
    dataSearchNoun () {
      return this.isUser ? this.$LOCAL('User') : this.$LOCAL('Group')
    },
    dataSortFields () {
      return this.isUser ? this.fields : this.groupFields
    },
    /**
     * If User then get the results from the computed listOfUsers or listOfGroups
     */
    dataListOfItems () {
      return this.isUser ? this.listOfUsers : this.listOfGroups
    },
    /**
     * If User then returns 'Settings Edit User' else return 'Settings Edit Group'
     */
    dataEditItems () {
      return this.isUser ? 'Settings Edit User' : 'Settings Edit Group'
    },
    /**
     * If groupItems.fields exist returns the fields for the groups under sort options
     */
    dataItemsField () {
      return this.isUser
        ? this.usersInformation.fields || {}
        : this.groupItems.fields || {}
    },
    /**
     * If it is a user, usersInformation.next would be returned and the button in manage users page would appear
     * If it is not a user and a group, groupItems.next would be returned and the button in manage group page would appear
     */
    dataLoadMore () {
      return this.isUser ? this.usersInformation.next : this.groupItems.next
    },
    /**
     * This method is to evaluate and determine if Insight Portal is setup using Active Diretory as the authentication
     * method.
     */
    isActiveDirectoryMode () {
      return this.rootUserData.authentication_method === 'AD'
    },
    /**
    * This method take into consideration if Active Directory mode and if the route is from the User page
    * to enable/disable the Create button
    */
    disableCreateButtonForUserManagementPage () {
      return this.isActiveDirectoryMode && this.isUser
    }
  },
  async mounted () {
    this.loading = true // Set loading on mount to handle slow data fetching
    await this.fetchQueryList()
  },
  methods: {
    ...mapActions('config/admin', [
      'getUsers',
      'getGroups',
      'getGroupQueries',
      'softDeleteUser',
      'deleteGroup'
    ]),
    /**
     * This method is used to fetch the data for groups and users from the backend with different conditions of isAppend
     */
    async fetchQueryList (isAppend = false) {
      this.loading = true
      if (isAppend) {
        this.page++
      } else {
        this.page = 1
      }
      // params sent to the backend for sort,search,pagination
      const params = {
        page: this.page,
        search: this.searchTerm,
        ordering: this.getSortField(this.sortBy).join(','),
        perPage: 10
      }
      if (this.$route.meta.action === 'users') {
        await this.getUsers({ params, isAppend })
      } else if (this.$route.meta.action === 'groups') {
        await this.getGroupQueries({ params, isAppend })
      }
      this.loading = false
      this.setHelperText()
    },
    /**
     *  This method is triggerd when user presses the cancel button on the modal
     */
    cancelDelete () {
      this.showDeleteModal = false
    },
    /**
     *  This method is triggered when user clicks on the trash logo.
     *  It negates the showDeleteModal (default = false), hence showing the modal
     *  Local Variable userIdToDelete and userNameToDelete is set when calling the vuex action softDeleteUser
     */
    deleteThisRecord (item) {
      this.showDeleteModal = !this.showDeleteModal
      if (this.isUser) {
        this.userIdToDelete = item.id
        this.userOrGroupNameToDelete = item.username
      } else {
        this.userIdToDelete = item.id
        this.userOrGroupNameToDelete = item.name
      }
    },
    /**
     *  This method is triggered when submit button is pressed on the modal.
     *  Vuex action softDeleteUser is called with userIdToDelete which is appended to the url for specific user lookup.
     *  It validates whether the user has inputed the corrected text 'Yes'.
     *  If validation is successful and there are 2 or more superuser who are not soft deleted, a successful toast message displays.
     *  If there are less than 2 superuser who are not soft deleted, a error toast message is shown.
     *  Likewise, if user inputs the wrong value, an error toast message is shown.
     */
    async confirmDelete () {
      let result = false
      if (
        this.confirmationTextModel ===
        this.$CONFIG('ADMIN').MODAL_CONTENT.CONFIRM_TEXT
      ) {
        if (this.isUser) {
          // Delete User
          result = await this.softDeleteUser(this.userIdToDelete)
        } else {
          // Delete User Group
          result = await this.deleteGroup(this.userIdToDelete)
        }
        if (result) {
          await this.fetchQueryList()
          this.$bvToast.toast(
            `${this.userOrGroupNameToDelete}${
              this.$CONFIG('ADMIN').MODAL_CONTENT.DELETE_SUCCESS
            }`,
            {
              title: this.$CONFIG('ADMIN').TOAST_TITLE.SUCCESS,
              autoHideDelay: 5000,
              variant: 'success',
              opacity: 1
            }
          )
          this.confirmationTextModel = ''
        } else {
          if (this.showToast) {
            this.$bvToast.toast(this.error.message, {
              title: this.$CONFIG('ADMIN').TOAST_TITLE.ERROR,
              autoHideDelay: 5000,
              variant: 'danger',
              opacity: 1
            })
          }
          this.confirmationTextModel = ''
        }
      } else {
        this.$bvToast.toast(this.$LOCAL('CONFIRMATION_ERROR_MESSAGE'), {
          title: this.$CONFIG('ADMIN').TOAST_TITLE.ERROR,
          autoHideDelay: 5000,
          variant: 'danger',
          opacity: 1
        })
        this.confirmationTextModel = ''
      }
    },
    /**
     * If isUser is true then the page will move to a create page for user else for groups
     */
    goToCreate () {
      return this.isUser
        ? this.$router.push({ name: 'Settings Create User' })
        : this.$router.push({ name: 'Settings Create Group' })
    },
    /**
     * This method gets the searchTerm from the search-sort-control component being passed back
     */
    doSearch (searchTerm) {
      this.searchTerm = searchTerm
      this.fetchQueryList(false)
    },
    /**
     * This methods handles the sorting logic and not displaying the options for it
     */
    sort (sortBy) {
      this.sortBy = sortBy
      this.fetchQueryList(false)
    },
    /**
     * This methods gets the sort field based on sortBy
     */
    getSortField (sortBy) {
      let sortField
      if (sortBy.includes('-role')) {
        sortField = ['is_superuser', 'is_staff']
      } else if (sortBy.includes('role')) {
        sortField = ['-is_superuser', '-is_staff']
      } else if (sortBy.includes('-status')) {
        sortField = ['is_active', 'id']
      } else if (sortBy.includes('status')) {
        sortField = ['-is_active', 'id']
      } else {
        sortField = sortBy
      }
      return sortField
    },
    /**
     * This method routes to the edit page based on isUser data users or groups
     */
    editOption (item) {
      this.$router.push({
        name: this.isUser ? 'Settings Edit User' : 'Settings Edit Group',
        params: { id: item.id, name: this.isUser ? null : item.name }
      })
    },
    /**
     * This methods access the attribute username in item object if the user is in manageuser.
     * Otherwise it accesses the attribute name in item object if the uesr is in managegroup
     */
    dataLoadCardTitle (item) {
      return this.isUser ? item.username : item.name
    },
    /**
     * This method is triggerd by an emitted event called confirmationTextInput.
     * Local data confirmationTextModel is set to the input value.
     */
    setConfirmationText (value) {
      this.confirmationTextModel = value
    },
    /**
     * This method is used to conditionally toggle the css class between editbutton and an
     * empty css class.
     * If the username card belongs to the currently logged in user, it will return an empty
     * css class, otherwise returning editbutton class.
     */
    checkLoggedInUserAgainstUser (item) {
      return item.username !== this.rootUserData.username ? 'editbutton' : ''
    },
    setHelperText () {
      const title = this.helperTextTooltip
      this.helperTextField.forEach(item => {
        this.helperTextData.push({
          [item]: title
        })
      })
    },
    /**
     * This method is to evaluate if the list item selected is the same as the logged in user.
     * @param item containing user
     * @returns {boolean}
     */
    disableDeleteButton (item) {
      return item.username !== this.rootUserData.username
    },
    showSyncModal () {
      this.isSyncModal = true
    },
    hideSyncModal () {
      this.isSyncModal = false
    }
  }
}
</script>
<style scoped lang="scss">
.editbutton {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
.search-controls {
  padding: 0 14px;
}
.modal-class {
  display: flex;
  flex-direction: row;
  p {
    flex-grow: 0;
  }
}
</style>
