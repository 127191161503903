<template>
  <div>
    <b-row>
        <b-col>
          <div class="heading">
            {{indexCategoryTitle}}
          </div>
          <div class="items">
            <div class="item" v-for="(item, index) of indexItemList" :key="index">
              <div class="status">
                <b-spinner small v-if="item.status === 'Indexing in Progress'" :label="`${$LOCAL('COMMON_WORD').GENERATING}...`"></b-spinner>
                <div class="cross" v-if="item.status === 'Indexing Error'"></div>
                <div class="check" v-if="item.status === 'Indexing Completed'"></div>
              </div>
              <div class="name">
                {{ indexItemList[index].model_label }}
              </div>
            </div>
          </div>
        </b-col>
        <b-col>
          <div class="heading">
            {{lastIndexedOnCaption}}
          </div>
          <div class="items">
            <div class="item" v-for="(item, index) of indexItemList" :key="index">
              <div class="name" v-if="showStatus(item.status)">
                 {{item.status}}
              </div>
              <div class="name" v-else>
                 {{ $moment.utc(indexItemList[index].last_index_on).local().format('L LTS') }}
              </div>
              <b-button class="button" v-if="false"
              variant="primary"
              :disabled='disableReIndexButtonBoolean'
              v-b-tooltip.hover title="Re-Index">
                <LaRefresh class="icon"/>
              </b-button>
            </div>
          </div>
        </b-col>
    </b-row>
  </div>
</template>

<script>
import LaRefresh from '@/assets/la-refresh.svg'

export default {
  name: 'IndexStatusItem',
  components: {
    LaRefresh
  },
  props: {
    indexItemList: {
      type: Array,
      required: true
    },
    indexCategoryTitle: {
      type: String,
      required: true
    },
    disableReIndexButtonBoolean: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    lastIndexedOnCaption () {
      return `${this.$CONFIG('WIZARD_PAGE_4').TEXT.LAST_INDEXED_ON}`
    }
  },
  methods: {
    showStatus (status) {
      return status !== 'Indexing Completed'
    }
  }

}
</script>

<style lang="scss" scoped>

  @media only screen and (max-width:805px) {
    .items {
      .item {
        display: flex;
        text-align: left;
        min-height: 60px;
      }
    }
    .heading {
      text-align: left;
      padding: 5px 10px 5px 0px;
      min-height: 60px;
      font-weight: bold;
    }
  }

  @media only screen and (max-width:992px) and (min-width: 806px) {
    .items {
      .item {
        display: flex;
        text-align: left;
      }
    }
    .heading {
      text-align: left;
      padding: 5px 10px 5px 0px;
      font-weight: bold;
    }
  }

  @media only screen and (max-width: 1238px) and (min-width: 992px) {
    .items {
      .item {
        display: flex;
        text-align: left;
        min-height: 60px;
      }
    }
    .heading {
      text-align: left;
      padding: 5px 10px 5px 0px;
      min-height: 60px;
      font-weight: bold;
    }
  }

  .heading {
    text-align: left;
    padding: 5px 10px 5px 0px;
    font-weight: bold;
  }

  .items {
    .item {
      display: flex;
      text-align: left;

      .status {
        align-self: center;
        padding-right: 5px;
      }
    }
  }

  .icon {
    min-height: 20px;
    min-width: 20px;
    position: relative;
    right: 9px;
    bottom: 7px;
    fill: white
  }

  .button {
    padding: 5px 10px;
    margin-left: 5px;
    align-self: center;
    height: 25px;
    width: 25px;
    text-align: center
  }

  .check {
    height: 16px;
    width: 12px;
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='16' " +
    "viewBox='0 0 12 16'%3E%3Cpath fill-rule='evenodd' fill='%2328a745' d='M12 5l-8 8-4-4 1.5-1.5L4 10l6.5-6.5L12 5z'/%3E%3C/svg%3E");
  }

  .cross {
    height: 12px;
    width: 12px;
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' " +
    "viewBox='0 0 24 24'%3E%3Cpath fill='red' d='M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 " +
    "8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z'/%3E%3C/svg%3E");
  }

  .name {
      flex-grow: 1;
      padding: 5px 0px 5px 0px;
      align-self: center;
    }

</style>
